import React, { useEffect, useRef, useCallback } from 'react'
import Lottie from 'lottie-react'
import heroAnimation from '../../animations/new/home-hero-desktop.json'
import mobileHeroAnimation from '../../animations/new/home-hero-mobile.json'
import styles from './homepageHero.module.css'
import headerStyles from '../../components/header.module.css'
import { useWindowSize } from '../../hooks/useWindowSize.hook'

const HomepageHero = ({ title }) => {
  const animationRef = useRef(null)
  const titleRef = useRef(null)
  const titleWrapRef = useRef(null)
  const { width } = useWindowSize()

  useEffect(() => {
    document.body.setAttribute('data-no-scroll', 'true')
    const hasPlayedAnimation = localStorage.getItem('hasPlayedAnimation')

    if (!hasPlayedAnimation) {
      document.getElementsByTagName('header')[0].classList.add(headerStyles.transparent)
    }

    if (hasPlayedAnimation) {
      document.getElementsByTagName('header')[0].classList.remove(headerStyles.transparent)
      document.body.removeAttribute('data-no-scroll')
      titleRef.current.classList.add(styles.homepageHeroTitleWrapAnimation, styles.homepageHeroUnAnimation)
      animationRef.current.classList.add(styles.homepageHeroWrapAnimation, styles.homepageHeroUnAnimation)
    }
  }, [])

  const dotLottieRefCallback = useCallback(() => {
    const hasPlayedAnimation = localStorage.getItem('hasPlayedAnimation')

    if (!hasPlayedAnimation) {
      document.body.removeAttribute('data-no-scroll')

      if (titleRef.current) {
        titleRef.current.classList.add(styles.homepageHeroTitleWrapAnimation)
        animationRef.current.classList.add(styles.homepageHeroWrapAnimation)

        setTimeout(() => {
          if (titleWrapRef.current) {
            const textElement = titleWrapRef.current

            const splitTextIntoLines = () => {
              const containerWidth = textElement.clientWidth
              const words = textElement.textContent.split(' ')
              textElement.innerHTML = ''

              let currentLine = ''
              const lines = []

              words.forEach((word) => {
                const testLine = currentLine + (currentLine ? ' ' : '') + word

                const testElement = document.createElement('span')
                testElement.classList.add(styles.homepageHeroTextLine)
                testElement.style.position = 'absolute'
                testElement.style.visibility = 'hidden'
                testElement.style.whiteSpace = 'nowrap'
                testElement.textContent = testLine
                textElement.appendChild(testElement)

                if (testElement.clientWidth > containerWidth) {
                  lines.push(currentLine)
                  currentLine = word
                } else {
                  currentLine = testLine
                }

                textElement.removeChild(testElement)
              })

              if (currentLine) {
                lines.push(currentLine)
              }

              lines.forEach((line, index) => {
                const lineElement = document.createElement('div')
                lineElement.classList.add(styles.homepageHeroTextLine)
                lineElement.style.animationDelay = `${index * 0.5}s`
                lineElement.textContent = line
                textElement.appendChild(lineElement)
              })
            }

            splitTextIntoLines()
          }
        }, 250)
        localStorage.setItem('hasPlayedAnimation', '1')
      }
      setTimeout(() => {
        document.getElementsByTagName('header')[0].classList.remove(headerStyles.transparent)
      }, 750)
    }
  }, [animationRef])

  return (
    <div className={styles.homepageHero}>
      <div className={styles.animationWrap} ref={animationRef}>
        <Lottie
          animationData={width > 768 ? heroAnimation : mobileHeroAnimation}
          loop={false}
          onComplete={() => {
            if (!localStorage.getItem('hasPlayedAnimation')) {
              dotLottieRefCallback()
            }
          }}
        />
      </div>
      <div className={styles.homepageHeroTitle} ref={titleRef}>
        <div className={styles.homepageHeroTitleWrap}>
          <div ref={titleWrapRef} dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        <div className={styles.homepageHeroScroll}>
          <div className={styles.homepageHeroScrollIndicator} />
          Scroll
        </div>
      </div>
    </div>
  )
}

export default HomepageHero
