import React, { useMemo } from 'react'
import styles from './resourcesAndContent.module.css'
import * as linkStyle from '../../styles/components/links.module.css'
import Icon from '../icons'
import { Link } from 'gatsby'
import Card from '../hub/card/card'
import { Image } from '../Image'
import { calculateRawContent } from '../../lib/helpers'

const ResourcesAndContent = ({
  name,
  quickNews = [],
  leftColumn = [],
  rightColumn = [],
  featuredPost,
  featuredName,
  featuredDescription,
  featuredNameButton
}) => {
  const featuredTitle = featuredName || featuredPost?.title
  const featuredText = featuredDescription || featuredPost?.blurb
  const featuredLinkText = featuredNameButton || (featuredPost?._type === 'written' ? 'read' : 'full video')

  const renderQuickNews = useMemo(() => (
    quickNews.map((post, index) => post && (
      <Card quickNews key={index} type='titles' data={post} />
    ))
  ), [quickNews])

  const renderLeftColumn = useMemo(() => (
    leftColumn.map((article, index) => (
      <div key={index} style={{ margin: '0 0 25px 0' }}>
        <Card
          page='home'
          options={{
            tags: true,
            imgStyles: { maxHeight: '266px' },
            imgSizes: [600, 335]
          }}
          type='simpleX'
          data={article}
        />
      </div>
    ))
  ), [leftColumn])

  const renderRightColumn = useMemo(() => (
    rightColumn.map((el, index) => (
      <div key={index} className={styles.rightContentInfo}>
        <Link to={el.link || `/content-hub/written/${el.slug.current}`} target='_blank'>
          <Image imgObj={el.mainImage || el.featureImage} props={{ width: 316, height: 177, alt: el.title }} />
          <h5>{el.title}</h5>
          <p>{el._type === 'written' ? calculateRawContent(el._rawContent) : el?.timeReads}</p>
        </Link>
      </div>
    ))
  ), [rightColumn])

  return (
    <div className={styles.resourcesAndContent}>
      <h3>{name}</h3>
      <div className={styles.resourcesAndContentImgContainer}>
        <Card
          page='home'
          type='simple'
          options={{ tags: true, imgSizes: [836, 508], imgStyles: { maxHeight: '508px' } }}
          section='featured'
          data={featuredPost}
        />
      </div>
      <div className={styles.resourcesAndContentItem}>
        <div>
          <h4>{featuredTitle}</h4>
          <p>{featuredText}</p>

          <div style={{ display: 'flex' }}><Link
            style={{ color: '#2F2F2F' }}
            to={((featuredPost.slug) && `/content-hub/${featuredPost._type}/${(featuredPost.slug?.current)}/`)}
            target='_blank'
            className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}
          >
            {featuredLinkText}
            <span>
              <div className={linkStyle.iconCircle} />
              <div className={`${linkStyle.icon} ${styles.icon}`}>
                <Icon symbol='newsArrowIcon' color='currentColor' />
              </div>
            </span>
          </Link>
          </div>
        </div>
        <div className={styles.quickNewsContainer}>
          <h5>Quick news</h5>
          {renderQuickNews}
        </div>
      </div>
      <div className={styles.subInfoContainer}>
        <div>
          {renderLeftColumn}
        </div>
        <div className={styles.rightContentMain}>
          {renderRightColumn}
        </div>
      </div>
      <div className={styles.viewMoreWrap}>
        <Link to='/content-hub/' className={linkStyle.linkPlus}>View more
          <span>
            <div className={linkStyle.iconPlus} />
          </span>
        </Link>
      </div>
    </div>
  )
}

export default React.memo(ResourcesAndContent)
